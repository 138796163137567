<template>
  <div class="page-block">
    <a-alert
      v-if="!componentMeta"
      class="page-block__error"
      :message="$t(`page.error.meta`, { type: entityType })"
      type="error"
      showIcon
    />

    <a-spin
      v-else
      :spinning="(!data && !requestError) || !config || loadingQueries > 0"
    >
      <div class="spin-wrap">
        <template v-if="fieldsMeta && config">
          <div class="page-block__title">
            <a-icon
              v-if="showSidebarButton"
              class="menu-toggle"
              type="menu"
              @click="toggleMenu"
            />
            <h1 class="ellipsis">{{ title }}</h1>
            <a-icon
              v-if="canEditModel"
              class="page-block__model-link"
              type="edit"
              @click="editModel"
            />
          </div>

          <the-filter
            v-if="filterParams"
            :pageConfig="config"
            :formConfig="formConfig"
            :tableConfig="tableConfig"
            :filters="filterParams"
            :configData="cachedConfig"
            :configLayout="CONFIG_LAYOUT"
            :fieldsMeta="fieldsMeta"
            :entityType="entityType"
            :canCreateModel="canCreateModel"
            @setFilterParams="setFilterParams"
            @createEntity="entityClick"
            @configUpdated="saveConfig"
            @pageSizeUpdated="updatePageSize"
          >
            <template
              v-if="data"
              #pagination
            >
              <a-pagination
                v-model="page"
                simple
                hideOnSinglePage
                :disabled="$apollo.queries.data.loading"
                :total="data.totalCount"
                :pageSize="config.pageSize"
              />
            </template>

            <template
              v-if="showImportButton"
              #last
            >
              <file-import :entityType="entityType" />
            </template>
          </the-filter>

          <component
            :is="rendererComponent"
            v-if="data"
            :entityType="entityType"
            :entityFields="fieldsMeta"
            :defaultSort="sortParams"
            :data="data"
            :config="config"
            :formConfig="formConfig"
            :tableConfig="tableConfig"
            :canReadModel="canReadModel"
            :canUpdateModel="canUpdateModel"
            @openInModal="(path) => $emit('openInModal', path)"
            @updateEntity="updateEntity"
            @setSortParams="setSortParams"
            @entityClick="entityClick"
            @updateRows="updateRows"
          />

          <a-alert
            v-else-if="requestError"
            type="error"
            :message="requestError"
          />
        </template>
      </div>
    </a-spin>
  </div>
</template>

<script>
import store from '@/store';
import { bus, deepClone, storage } from '@/helpers';
import TABLE_QUERY from '@/queries/table';
import EntityService from '@/services/EntityService';
import FormConfigService from '@/services/FormConfigService';
import TableConfigService from '@/services/TableConfigService';
import TheFilter from '@/components/page/filter/TheFilter.vue';
import TheGantt from '@/components/page/gantt/TheGantt.vue';
import TheKanban from '@/components/page/kanban/TheKanban.vue';
import TheTable from '@/components/page/table/TheTable.vue';
import TheTiles from '@/components/page/tiles/TheTiles.vue';
import TheCalendar from '@/components/page/calendar/TheCalendar.vue';
import TheMap from '@/components/page/map/TheMap.vue';
import FormsList from '@/components/page/forms-list/FormsList.vue';
import SoCourseList from '@/components/custom-entities/SoCourseList/SoCourseList.vue';
import SoPromocodes from '@/components/custom-entities/SoPromocodes/SoPromocodes.vue';
import AuroReleases from '@/components/custom-entities/AuroReleases/AuroReleases.vue';
import { CONFIG_LAYOUT, prepareConfig } from '@/components/page/config/configFormLayout.js';
import { getFilterRendererByFormRenderer } from '@/components/page/filter/fieldOperators.js';
import LadaItems from '@/components/custom-entities/LadaEditItemModeration/LadaItems.vue';
import LadaNewItems from '@/components/custom-entities/LadaNewItemModeration/LadaNewItems.vue';
import FileImport from './components/FileImport.vue';

export default {
  name: 'PageBlock',
  components: {
    TheFilter,
    TheGantt,
    TheKanban,
    TheTable,
    TheInlineTable: TheTable,
    TheTiles,
    TheCalendar,
    TheMap,
    FileImport,
    FormsList,
    SoCourseList,
    SoPromocodes,
    AuroReleases,
    LadaItems,
    LadaNewItems,
  },

  props: {
    pageUrl: {
      type: String,
      required: true,
    },
    entityType: {
      type: String,
      required: true,
    },
    showSidebarButton: {
      type: Boolean,
      required: true,
    },
    isUseConfig: {
      type: Boolean,
      default: true,
    },
    setupFilters: {
      type: Object,
      default: () => ({}),
    },
    setupSortParams: {
      type: Object,
      default: () => ({}),
    },
  },

  apollo: {
    data: {
      ...TABLE_QUERY,
      loadingKey: 'loadingQueries',
      debounce: 250,
      variables() {
        return {
          type: this.entityType,
          page: this.page - 1,
          pageSize: this.config.pageSize,
          filters: this.graphFilters,
          orders: this.sortParams,
        };
      },
      update({ table }) {
        let data = null;
        if (!table) {
          data = {
            totalCount: 0,
            totalPages: 0,
            hasMore: false,
            rows: [],
          };
        } else {
          data = {
            totalCount: table.totalCount,
            totalPages: Math.ceil(table.totalCount / this.config.pageSize),
            hasMore: table.hasMore,
            rows: table.documents,
          };
        }

        this.requestError = null;
        return data;
      },
      skip() {
        return !this.componentActive || !this.fieldsMeta || !this.cachedConfig;
      },
      error(error, vm, key, type, { variables }) {
        const errorMessage = this.$t(`page.error.getTable_notification`);
        this.data = null;
        this.requestError = this.$t(`page.error.getTable_onPage`, {
          variables: JSON.stringify(variables, ' ', 5),
          description: error.message,
        });
        this.emitError(errorMessage, error.message);
      },
    },
  },

  data() {
    return {
      loadingQueries: 0,
      CONFIG_LAYOUT,
      page: Number(this.$route.query.page) || 1,
      filterParams: null,
      sortParams: [],
      componentActive: true,
      cachedConfig: null,
      requestError: null,
      queryPageSize: Number(this.$route.query.pageSize),
      localStoredTableConfig: (storage.get('viewConfigData') || {})[this.pageUrl],
      configData: null,
    };
  },

  computed: {
    componentMeta() {
      return store.state.meta.components[this.entityType];
    },
    user() {
      return store.state.user;
    },
    canEditModel() {
      return (
        this.user.isConstructor && store.state.meta.entities.find((e) => e.name === this.entityType)
      );
    },
    customForms() {
      return (
        store.state.activeSidebarItem.customprops?.form?.actions?.map((form) =>
          typeof form === 'string' ? { action: form } : form,
        ) || []
      );
    },
    desktop() {
      return store.state.isDesktop;
    },
    config() {
      return this.cachedConfig?.config;
    },
    formConfig() {
      return FormConfigService.getFormConfig(this.entityType);
    },
    tableConfig() {
      return TableConfigService.getTableConfig(this.entityType);
    },
    title() {
      return this.formConfig.locale[store.state.lang].entity || this.entityType;
    },
    rendererComponent() {
      const customComponent = store.state.activeSidebarItem.customprops?.customComponent;
      if (customComponent) return customComponent;
      if (this.customForms.length) return 'forms-list';
      return `the-${this.config.displayType.value}`;
    },
    fieldsMeta() {
      const fields = this.formConfig.tabs.map((tab) => tab.columns.flat()).flat();
      return fields.map((fieldMeta) => ({
        ...fieldMeta,
        ...this.componentMeta.fields.find(({ name }) => name === fieldMeta.name),
      }));
    },
    isTable() {
      return this.config.displayType.value === 'table';
    },
    graphFilters() {
      return (this.filterParams || []).map(({ field, operator, value }) => {
        if (value.length === 1) {
          return this.getGraphFilterItem(field, operator, value[0], 0);
        }

        return {
          field,
          operator: 'OR',
          filters: value.map((v, index) => this.getGraphFilterItem(field, operator, v, index)),
        };
      });
    },
    operations() {
      return this.componentMeta.operations || {};
    },
    metaConfig() {
      return this.componentMeta.config || {};
    },
    canCreateModel() {
      return this.operations.CREATE && !this.customForms.length;
    },
    canReadModel() {
      return this.operations.READ;
    },
    canUpdateModel() {
      return this.operations.UPDATE;
    },
    showImportButton() {
      return store.state.meta.entities.find((e) => e.name === this.entityType);
    },
  },

  watch: {
    $route(to, from) {
      if (to.path === from.path && to.fullPath !== from.fullPath) {
        this.filterParams = null;
        this.sortParams = [];
        this.$nextTick(() => {
          this.sortParams = this.getInitialSortParams();
          this.filterParams = this.getInitialFilterParams();
        });
      }
    },
    configData: {
      async handler() {
        await this.resetConfig();
        this.sortParams = this.getInitialSortParams();
        this.filterParams = this.getInitialFilterParams();
      },
    },
    page() {
      this.updatePageQueryParams();
    },
    formConfig: {
      deep: true,
      handler() {
        this.resetConfig();
      },
    },
  },

  activated() {
    this.componentActive = true;
  },

  deactivated() {
    this.componentActive = false;
  },

  async created() {
    this.configData = this.getBaseConfig();
    bus.$on('refetchTable', this.refetchTable);
  },

  destroyed() {
    bus.$off('refetchTable', this.refetchTable);
  },

  methods: {
    getGraphFilterItem(field, operator, value, index) {
      const { renderer } = this.componentMeta.fields.find(({ name }) => name === field);

      if (renderer === 'refs') value = [value.value];
      if (renderer === 'ref') value = value.value;
      if (['date', 'date-time'].includes(renderer)) value = Number(value);

      return {
        field,
        operator: operator[index],
        value,
      };
    },

    async resetConfig() {
      let configData = deepClone(this.configData);
      if (configData) {
        configData = {
          ...configData,
          config: await prepareConfig(
            configData.config,
            this.componentMeta.fields,
            CONFIG_LAYOUT,
            this.entityType,
            this.tableConfig,
          ),
        };
      }

      // Has table columns config
      if (this.tableConfig?.columns) {
        Object.entries(this.tableConfig?.columns).forEach(([name, params]) => {
          if (!params.hidden && !configData.config.displayFields.find((f) => f.name === name)) {
            // Add custom columns if not already exist in saved local config
            configData.config.displayFields.push({
              name,
              hidden: false,
            });
          } else if (params.hidden) {
            // Remove columns hidden by tables config
            const ind = configData.config.displayFields.findIndex((f) => f.name === name);
            if (ind > -1) configData.config.displayFields.splice(ind, 1);
          }
        });

        // If no local config exist presort columns by tables config
        if (!this.localStoredTableConfig) {
          const first = [];
          const mid = [];
          const last = [];
          configData.config.displayFields.forEach((item) => {
            const position = this.tableConfig.columns[item.name]?.position;
            if (position === 'left') first.push(item);
            else if (position === 'right') last.push(item);
            else mid.push(item);
          });

          configData.config.displayFields = first.concat(mid).concat(last);
        }
      }

      this.cachedConfig = configData;
    },

    routeQueryUpdate(queryParamsToAdd, paramsFilter = () => true) {
      const queryParamsToKeep = Object.entries(this.$route.query).filter(paramsFilter);

      if (this.isUseConfig) {
        this.$router
          .replace({
            query: {
              ...Object.fromEntries(queryParamsToKeep),
              ...queryParamsToAdd,
            },
          })
          .catch(() => {});
      }
    },

    updatePageQueryParams() {
      this.routeQueryUpdate(
        {
          page: this.page,
          pageSize: this.cachedConfig.config.pageSize,
        },
        ([key]) => !['page', 'pageSize'].includes(key),
      );
    },

    refetchTable(type) {
      if (!type || type === this.entityType) {
        this.requestError = null;
        this.$apollo.queries.data.refresh();
      }
    },

    getBaseConfig() {
      const storedConfig = this.localStoredTableConfig || {};
      return {
        title: `pageblock_${this.entityType}`,
        config: {
          ...storedConfig,
          pageSize: this.queryPageSize || storedConfig.pageSize,
        },
      };
    },

    getInitialSortParams() {
      let sortParams = [];
      if (this.isUseConfig) {
        const queryParams = this.isTable && this.$route.query;
        sortParams = (this.isTable && storage.get(`tableSorting_${this.pageUrl}`)) || [];

        if (queryParams.sort) {
          sortParams = [
            {
              field: queryParams.sort,
              direction: (queryParams.sortDir || '').toUpperCase(),
            },
          ];
        }
      } else if (Object.keys(this.setupSortParams).length) {
        sortParams = [
          {
            field: this.setupSortParams.sort,
            direction: (this.setupSortParams.sortDir || '').toUpperCase(),
          },
        ];
      }

      return sortParams;
    },

    parseFilterParamValue(key, value, isArray = true) {
      const fieldExist = this.fieldsMeta.find((field) => field.name === key.split('f_')[1]);
      if (!fieldExist) return null;

      if (isArray) {
        value = value.slice(1, -1).split(/;/);
        const list = value.map((v) => this.parseFilterParamValue(key, v, false));

        return {
          field: key.split('f_')[1],
          operator: list.map((i) => i.operator),
          value: list.map((i) => i.value),
        };
      }

      value = value.split(/\./);
      let operator = value.shift();
      if (operator === 'o') {
        operator = value.shift();
        value = {
          value: value.shift(),
          title: value.join('.'),
        };
      } else {
        value = value.join('.');
      }

      const { valueProcessor = (v) => v } =
        getFilterRendererByFormRenderer(
          this.componentMeta.fields.find((f) => f.name === key.split('f_')[1]).renderer,
        ) || {};

      return {
        field: key.split('f_')[1],
        operator,
        value: valueProcessor(value),
      };
    },

    getInitialFilterParams() {
      let filterParams = [];
      if (this.isUseConfig) {
        filterParams = storage.get(`tableFiltering_${this.pageUrl}`) || [];
      }

      // Если есть фильтры в адресной строке, берём их вместо сохранённых
      const queryFilterParams = Object.entries(
        this.isUseConfig ? this.$route.query : this.setupFilters,
      ).filter(([key]) => key.startsWith('f_'));

      if (queryFilterParams.length) {
        filterParams = queryFilterParams
          .map(([key, value]) => this.parseFilterParamValue(key, value))
          .filter((filterItem) => !!filterItem);
      } else {
        // Исключаем сохранённые фильтры по несуществующим полям
        filterParams = filterParams.filter((filterItem) =>
          this.fieldsMeta.find((field) => field.name === filterItem.field),
        );
      }

      return filterParams;
    },

    setSortParams(params) {
      if (this.isUseConfig) {
        storage.set(`tableSorting_${this.pageUrl}`, params);
      }

      this.sortParams = params;
      const queryParamsToAdd = {
        sort: params[0]?.field,
        sortDir: params[0]?.direction.toLowerCase(),
      };

      this.routeQueryUpdate(queryParamsToAdd);
    },

    getQueryParamForFilterItem({ field, operator, value }) {
      const list = value.map((v, index) => {
        if (typeof v === 'object') {
          v = `${v.value}.${v.title}`;
          return `o.${operator[index]}.${v}`;
        }

        return `${operator[index]}.${v}`;
      });

      return [`f_${field}`, `[${list.join(';')}]`];
    },

    setFilterParams(params, initialApply = false) {
      if (this.isUseConfig) {
        storage.set(`tableFiltering_${this.pageUrl}`, params);
      }

      if (!initialApply) {
        this.filterParams = params;
        this.page = 1;
      }

      const queryParamsToAdd = params.map(this.getQueryParamForFilterItem);
      this.routeQueryUpdate(Object.fromEntries(queryParamsToAdd), ([key]) => !key.startsWith('f_'));
    },

    async entityClick(id) {
      let error = null;

      if (id && !this.canReadModel) {
        if (!this.canReadModel)
          error = this.$t('entity.error.noReadRights', { name: this.entityType });
      }

      if (!id) {
        if (!this.canCreateModel)
          error = this.$t('entity.error.noCreateRights', { name: this.entityType });
        else id = id || `_temp_${+new Date()}`;
      }

      if (error) {
        return bus.$emit('error', {
          message: error,
          type: 'warning',
        });
      }

      this.$emit('entityClick', this.entityType, id);
    },

    async updateEntity(id, data, updatedData) {
      if (!this.canUpdateModel) return;

      const draft = store.mutate.getFormDraft(this.entityType, id);
      if (draft) Object.assign(draft.data, updatedData);

      await EntityService.update(this.fieldsMeta, {
        id,
        type: this.entityType,
        data,
      });
    },

    toggleMenu() {
      bus.$emit('toggleMenu');
    },

    updateRows(rows) {
      this.data.rows = rows;
    },

    saveConfig() {
      const storaged = {};
      const storagedKeys = ['displayFields', 'displayType', 'pageSize'];

      Object.entries(this.config).forEach(([key, value]) => {
        if (storagedKeys.includes(key)) {
          storaged[key] = value;
        }
      });

      Object.assign(this.configData.config, storaged);

      const defaultStorage = storage.get('viewConfigData') || {};
      storage.set('viewConfigData', {
        ...defaultStorage,
        [this.pageUrl]: storaged,
      });
    },

    updatePageSize() {
      this.page = 1;
      this.saveConfig();
      this.updatePageQueryParams();
    },

    editModel() {
      this.$router.push({
        name: 'DataPageModel',
        params: {
          type: this.entityType,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.page-block {
  &__title {
    position: relative;
    flex-basis: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    height: 42px;

    h1 {
      display: inline-block;
      font-size: 28px;
      font-weight: 700;
      vertical-align: sub;
      margin-bottom: 0;
      margin-right: 30px;
    }

    .menu-toggle {
      width: 30px;
      margin-right: 15px;
    }

    .ant-btn {
      position: relative;
      top: 3px;
    }
  }

  &__import {
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &__icon {
      font-size: 18px;
      display: flex;
    }

    &-input {
      position: absolute;
      opacity: 0.01;
      z-index: -1000;
    }
  }

  &__head-pager {
    display: flex;
    align-items: center;
  }

  &__model-link {
    padding: 8px;
    font-size: 16px;
    margin-left: -25px;
    margin-top: 7px;

    &:hover {
      color: $antBlue;
    }
  }

  & + & {
    margin-top: 50px;
  }
}

@media (min-width: $desktopBreakpoint) {
  .menu-toggle.anticon {
    display: none;
  }
}
</style>
